import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { fetchHumidityData } from "./utils/api"

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;





const geoJson = {
  'type': 'FeatureCollection',
  'features': [
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [74.328732, 31.537923]
        // 'coordinates': [-77.032, 38.913]

      },
      'properties': {
        "temperature": 24,
        "date": "2024-07-25", "time": "12:45:12",
        "humidity": 40.89
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [74.35071000, 31.55800000]
      },
      'properties': {
        "temperature": 25,
        "date": "2024-07-25", "time": "12:45:12",
        "humidity": 38.48
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [74.3643, 31.4779]
      },
      'properties': {
        "temperature": 26,
        "date": "2024-07-25", "time": "12:45:12",
        "humidity": 48.48
      }
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [74.3587, 31.5204]
      },
      'properties': {
        "temperature": 28,
        "date": "2024-07-25", "time": "12:45:12",
        "humidity": 38.48
      }
    },


    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [74.3293, 31.4818]
      },
      'properties': {
        "temperature": 24,
        "date": "2024-07-25", "time": "12:45:12",
        "humidity": 38.48
      }
    }, {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [74.2707, 31.4728]
      },
      'properties': {
        "temperature": 24,
        "date": "2024-07-25", "time": "12:45:12",
        "humidity": 38.48
      }
    }, {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [74.4022, 31.5241]
      },
      'properties': {
        "temperature": 25,
        "date": "2024-07-25", "time": "12:45:12",
        "humidity": 38.48
      }
    }, {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [74.2717, 31.4451]
      },
      'properties': {
        "temperature": 23,
        "date": "2024-07-25", "time": "12:45:12",
        "humidity": 38.48
      }
    }
  ]


};

const App = () => {
  const mapContainerRef = useRef();
  const mapRef = useRef();
  useEffect(() => {
    const monument = [74.3587, 31.5204];
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v9',
      center: monument,
      zoom: 11,
      minZoom: 1
    });

    // const nav = new mapboxgl.NavigationControl();
    // mapRef.current.addControl(nav, 'top-right');

    mapRef.current.on('load', async () => {
      await mapRef.current.loadImage('icons8-iot-64.png', async (error, image) => {
        if (error) {
          console.log(error);
        }
        // Add the image to the map style.
        if (!mapRef.current.hasImage('iot-icon'))
          await mapRef.current.addImage('iot-icon', image);
      });

      const humidityData = await fetchHumidityData(
        `${process.env.REACT_APP_BASE_URL}/humidity-data`
      );
      // if (humidityData?.success) {
      const data = humidityData?.data;

      console.log({ data })

      await mapRef.current.addSource('m5-data', {
        type: 'geojson',
        data: data,
      });
      await mapRef.current.addLayer({
        id: 'm5-circle-layer',
        type: 'circle',
        source: 'm5-data',
        paint: {
          "circle-color": "rgba(117, 207, 240,0.5)",
          'circle-stroke-color': 'transparent',
          'circle-stroke-width': 20,
          'circle-radius': 50,
        },
      });
      await mapRef.current.addLayer({
        id: 'm5-data-layer',
        type: 'symbol',
        source: 'm5-data',
        paint: {
          "text-color": "#000000",
        },
        layout: {
          'icon-image': 'iot-icon',
          'icon-size': 0.4,
          'text-field': `Temp: {temperature} °C \n Humidity: {humidity}% \n Date: {date}\n Time: {time}`,
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 10,
          'icon-anchor': 'bottom',
          'text-anchor': 'top',
          "text-justify": "left",
          'text-offset': [0, -1.5],
          'icon-offset': [0, -45],
        },
      });

    })

    mapRef.current.on('mouseenter', 'm5-data-layer', () => {
      mapRef.current.getCanvas().style.cursor = 'pointer';
    });
    mapRef.current.on('mouseleave', 'm5-data-layer', () => {
      mapRef.current.getCanvas().style.cursor = '';
    });

    mapRef.current.on('click', 'm5-data-layer', (e) => {
      const zoom = mapRef?.current?.getZoom();
      if (zoom < 15) {
        mapRef.current.flyTo({
          center: e?.features[0]?.geometry.coordinates,
          zoom: 15,
        });
      } else {
        mapRef.current.flyTo({
          center: e?.features[0]?.geometry.coordinates,
        });
      }
    });
    return () => mapRef.current.remove();

  }, []);

  return (
    <>
      <div ref={mapContainerRef} style={{ height: '100vh', width: "100vw" }} />
    </>
  );
};

export default App;