import axios from "axios";

// function formatToTwoDecimalPlaces(value) {
//     let number = parseFloat(value);
//     return number % 1 === 0 ? number : number.toFixed(2);
// }
export async function fetchHumidityData(url) {
    try {
        const response = await axios.get(url);
        let data = response?.data;
        data = {
            ...data,
            features: [...data.features[0]]
        }
        console.log({ data }, 'r=here')
        return { data: data, success: true };

    } catch (error) {
        console.log("Error", error);
        return { response: error.response, success: false };
    }
}



